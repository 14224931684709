<template lang="pug">
popper.kebab-menu(
  :class="classes"
  trigger="click"
  :options="menuPopperOptions"
  :ref="`popper_${rowData.row._id}`"
  @created="popoverInstance = $event"
)
  .popper.shadow-sm(@mouseleave="closeMenu(rowData.row)")
    .kebab-options-wrapper
      .kebab-option.kebab-option-duplicate(
        @click="emitEvent('duplicate', rowData.row)"
        v-if="$slots.duplicate"
      )
        slot(name="duplicate")
      .kebab-option.kebab-option-edit(@click="emitEvent('edit', rowData.row)" v-if="$slots.edit")
        slot(name="edit")

      .kebab-option.kebab-option-preview(
        @click="emitEvent('preview', rowData.row)"
        v-if="$slots.preview"
      )
        slot(name="preview")

      .kebab-option.kebab-option-rename(
        @click="emitEvent('rename', rowData.row)"
        v-if="$slots.rename"
      )
        slot(name="rename")
      .kebab-option.kebab-option-delete(
        @click="emitEvent('delete', rowData.row)"
        v-if="$slots.delete"
      )
        slot(name="delete")
      .kebab-option.kebab-diag(
        v-if="$slots.diagnosticate"
        @click="emitEvent('startDiagnosticate', rowData.row)"
      )
        slot(name="diagnosticate")

      slot(name="custom")

  span.d-flex.m-auto(slot="reference")
    slot(name="reference")
      om-button.d-flex.flex-grow-0.justify-content-center.align-items-center.kebab-icon-btn.cursor-pointer(
        ghost
        iconOnly
        small
        icon="ellipsis-v"
      )
</template>
<script>
  import Vue from 'vue';
  import Popper from 'vue-popperjs';
  import Kebab from '@/components/Svg/Icons/Kebab.vue';
  import designSystemMixin from '../mixins/designSystem';

  const bus = new Vue();
  Vue.prototype.$bus = bus;

  export default {
    name: 'OmKebabMenu',
    components: { Kebab, Popper },
    mixins: [designSystemMixin],
    props: {
      rowData: {
        type: Object,
        default: () => ({
          row: {
            _id: 1,
          },
        }),
      },
    },
    data() {
      return {
        popoverInstance: null,
        menuPopperOptions: {
          html: true,
          container: 'body',
          placement: 'bottom',
          modifiers: {
            enabled: false,
            preventOverflow: { boundariesElement: 'window', escapeWithReference: true },
            offset: { offset: '-52,0' },
          },
        },
      };
    },
    mounted() {
      this.$bus.$on('closeKebabMenu', ({ _id: id }) => {
        this.closeMenu({ _id: id });
      });
    },
    beforeDestroy() {
      this.$bus.$off('closeKebabMenu');
    },
    methods: {
      emitEvent(type, data) {
        this.$emit(type, data);
        this.closeMenu(data);
      },
      closeMenu({ _id: id }) {
        if (this.$refs[`popper_${id}`]?.showPopper) {
          this.$refs[`popper_${id}`].doClose();
        }
      },
    },
  };
</script>
<style lang="sass">
  @mixin default-setting-option
    display: flex
    align-items: center
    min-width: 136px
    max-width: 220px
    height: 36px
    left: 0px
    top: 144px
    cursor: pointer
    font-size: 14px
    color: #505763
    &:hover
      background: #F1F2F4
    &:visited
      background: #F1F2F4
  @mixin default-setting-option-disabled
      opacity: .7
      cursor: default

  .kebab-option-disabled,.kebab-option-disabled:hover
    @include default-setting-option-disabled

  .kebab-option
    @include default-setting-option
    padding: 8px 12px 8px 12px
    justify-content: space-between
    &.disabled
      opacity: 0.7
      cursor: default
    &s-wrapper
      display: flex
      flex-direction: column
  .kebab-menu
    .popper
      background-color: white !important
      border: 1px solid #E3E5E8 !important
    .popper__arrow
      display: none !important
    .popper[x-placement^="left"]
      margin-right: 0rem
      margin-top: 1px
    .popper[x-placement^="bottom"]
      margin-top: 0px
    .popper[x-placement^="top"]
      margin-bottom: 0px
    a
      color: #505763
      &:hover
        text-decoration: none
</style>
